import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import {
  asyncDelete,
  asyncSearchList,
} from "../../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import TableApp from "../../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import { LuPlusCircle, LuTrash } from "react-icons/lu";
import FormComment2 from "../../../form/comment2/FormComment2";
import Tablepagination from "../../../tableapp/TablePagination";

interface IVoteSection {
  product: any;
}

const columns: TableColumn<any>[] = [
  {
    name: "Người đánh giá",
    width: "200px",
    wrap: true,
    selector: (row) =>
      row.name_user_created !== "public"
        ? row.name_user_created
        : row?.exfields?.ten_kh,
  },
  {
    name: "SĐT",
    width: "150px",
    wrap: true,
    selector: (row) => row.dien_thoai_kh,
  },
  {
    name: "Đánh giá",
    width: "120px",
    center: true,
    selector: (row) => row.rate,
  },
  {
    name: "Nội dung",
    width: "250px",
    wrap: true,
    selector: (row) => row.content,
    cell: (row) => {
      return (
        <Typography className="two-lines-ellipsis">{row.content}</Typography>
      );
    },
  },
];

const apiCode: string = "comment2";

function VoteSection({ product }: IVoteSection) {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const [data, setData] = useState<any[]>([]);
  const [openFormAdd, setOpenFormAdd] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [toggleClear, setToggleClear] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<number>(0);
  const [paginationOption, setPaginationOptions] = useState<{
    page: number;
    limit: number;
    totalRows: number;
  }>({ page: 1, limit: 20, totalRows: 0 });

  const handleCloseFormAdd = () => {
    setOpenFormAdd(false);
    setDefaultValues(null);
    setIsEdit(false);
  };

  // row per page change
  const handleRowPerPageChange = (value: number) => {
    setPaginationOptions({ ...paginationOption, page: 1, limit: value });
  };

  const handleRowClicked = (selectedRow: any) => {
    setDefaultValues(selectedRow);
    setIsEdit(true);
    setOpenFormAdd(true);
  };

  const deleteRows = async () => {
    if (selectedRows && selectedRows?.length > 0) {
      for (let i = 0; i < selectedRows?.length; i++) {
        const row = selectedRows[i];
        await asyncDelete({ apiCode, id: row?._id, token });
        if (i === selectedRows.length - 1) {
          setLoad(load + 1);
          setToggleClear(!toggleClear);
          setSelectedRows([]);
        }
      }
    }
  };

  const getVotes = async () => {
    if (loading) return;
    setLoading(true);
    const condition = {
      page: paginationOption.page,
      limit: paginationOption.limit,
      q: { of_id: product?._id },
    };
    const resp = await asyncSearchList({
      apiCode: "comment2",
      token,
      condition,
    });
    const respCount = await asyncSearchList({
      apiCode: "comment2",
      token,
      condition: { ...condition, count: 1 },
    });
    if (!resp && resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải đánh giá",
      });
    } else {
      setData(resp?.data || []);
      setPaginationOptions({
        ...paginationOption,
        totalRows: respCount?.data?.rows_number || 0,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.page, paginationOption.limit]);

  useEffect(() => {
    getVotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  return (
    <>
      {openFormAdd && (
        <FormComment2
          publicMode
          open={openFormAdd}
          isEdit={isEdit}
          onClose={handleCloseFormAdd}
          apiCode={apiCode}
          defaultValues={{
            of_id: product?._id,
            of_name: product?.ten_vt,
            ...defaultValues,
          }}
          setLoad={setLoad}
        />
      )}
      <Box sx={{ width: "100%", py: 1 }}>
        <Stack gap={1}>
          <Typography sx={{ fontWeight: 600 }}>Danh sách đánh giá</Typography>
          <Stack alignItems="flex-start" gap={1}>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ height: "42px" }}
            >
              <Button
                variant="contained"
                size="small"
                startIcon={<LuPlusCircle size={12} />}
                onClick={() => setOpenFormAdd(true)}
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: "common.white",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Thêm
              </Button>
              {selectedRows?.length > 0 && (
                <IconButton
                  onClick={deleteRows}
                  sx={{
                    backgroundColor: "error.main",
                    color: "common.white",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "error.main",
                    },
                  }}
                >
                  <LuTrash size={16} />
                </IconButton>
              )}
            </Stack>
            <Stack sx={{ border: "1px solid", borderColor: "divider" }}>
              <TableApp
                toggleCleared={toggleClear}
                columns={columns}
                data={data}
                progressPending={loading}
                rowPerPage={paginationOption.limit}
                selectableRows
                fixedHeader
                pagination
                onRowClicked={handleRowClicked}
                onSelectedRowsChange={setSelectedRows}
                fixedHeaderScrollHeight="300px"
              />
              {/* Pagination */}
              <Box
                sx={{
                  mt: "auto",
                  borderTop: "1px dashed",
                  borderColor: "divider",
                }}
              >
                {paginationOption.totalRows > 0 && (
                  <Tablepagination
                    currentPage={paginationOption.page}
                    onChangePage={(value) =>
                      setPaginationOptions({ ...paginationOption, page: value })
                    }
                    rowsPerPage={paginationOption.limit}
                    rowCount={paginationOption.totalRows}
                    onChangeRowsPerPage={handleRowPerPageChange}
                    paginationRowsPerPageOptions={[20, 50, 100, 500]}
                  />
                )}
              </Box>
              {/* Pagination end */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default VoteSection;
