import React, { useState } from "react";
import { IForm } from "../../../../types/form.type";
import ModalForm from "../../modal/ModalForm";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  asyncPostData,
  asyncPutData,
  uploadFile,
} from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import InfoSection from "./info-section/InfoSection";
import ImageSection from "./image-section/ImageSection";
import DinhMucSection from "./dinhmuc-section/DinhMucSection";
import DvtSection from "./dvt-section/DvtSection";
import { useBackdropContext } from "../../../../contexts/backdrop/BackdropProvider";
import DescriptionSection from "./description-section/DescriptionSection";
import TonDauKySection from "./tondauky-section/TonDauKySection";
import generateSlug from "slug";

const schema = yup.object({
  ten_vt: yup.string().required("Vui lòng nhập tên Sản phẩm"),
  don_vi_tinh: yup
    .object()
    .typeError("Vui lòng chọn đơn vị tính")
    .required("Vui lòng chọn đơn vị tính"),
});

function FormHangHoa({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const { setOpenBackdrop } = useBackdropContext();
  const [thumbnails, setThumbnails] = useState<{ [key: string]: File | null }>({
    picture: null,
    picture2: null,
    picture3: null,
  });
  const [dvts, setDvts] = useState<any[]>([]);
  const [tonDayKys, setTonDauKys] = useState<any[]>([]);
  const defaultData: { [key: string]: any } = {
    ma_vt: "",
    ten_vt: "",
    ma_vt2: "",
    don_vi_tinh: null,
    nhom_vat_tu: null,
    nhom_pos: null,
    gia_ban_le: "",
    gia_mua: "",
    vi_tri: "",
    quy_cach: "",
    slug: "",
    tg_tk: false,
    ton_toi_thieu: 0,
    ton_toi_da: 0,
    mieu_ta: "",
    sold_on_pos: false,
    is_app: false,
    sold_on_web: false,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        don_vi_tinh: defaultValues?.ma_dvt
          ? {
              ma_dvt: defaultValues.ma_dvt || "",
              ten_dvt: defaultValues.ten_dvt || "",
            }
          : null,
        nhom_vat_tu: defaultValues?.ma_nvt
          ? {
              _id: defaultValues.ma_nvt || "",
              ten_nvt: defaultValues.ten_nvt || "",
            }
          : null,
        nhom_pos: defaultValues?.exfields?.ma_nvt_pos
          ? {
              _id: defaultValues?.exfields?.ma_nvt_pos || "",
              ten_nvt: defaultValues?.exfields?.ten_nvt_pos || "",
            }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
    setThumbnails({
      picture: null,
      picture2: null,
      picture3: null,
    });
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { ten_vt, don_vi_tinh, nhom_vat_tu, nhom_pos, slug, ...fields } =
      values;

    const result: { [key: string]: any } = {
      ...fields,
      ten_vt,
      slug: slug || generateSlug(ten_vt, "-"),
      ma_dvt: don_vi_tinh?.ma_dvt || "",
      ten_dvt: don_vi_tinh?.ten_dvt || "",
      ma_nvt: nhom_vat_tu?._id || "",
      ten_nvt: nhom_vat_tu?.ten_nvt || "",
      exfields: {
        ...(defaultValues?.exfields || {}),
        ma_nvt_pos: nhom_pos?._id || "",
        ten_nvt_pos: nhom_pos?.ten_nvt || "",
      },
    };

    // save picture
    const entrieImages = Object.entries(thumbnails);
    for (let i = 0; i < entrieImages.length; i++) {
      const entry = entrieImages[i];
      if (!!entry[1]) {
        const formData = new FormData();
        formData.append("file", entry[1]);
        const respFile = await uploadFile({
          formData,
          folder: "products",
          token,
        });
        if (respFile.status === 200) {
          result[entry[0]] = respFile?.data?.fileUrl;
        }
      } else if (entry[1] === undefined) {
        result[entry[0]] = "";
      }
    }
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      // ton dau ky
      if (resp?.data?.ma_vt && tonDayKys?.length > 0 && !isEdit) {
        setOpenBackdrop?.(true);
        for (let i = 0; i < tonDayKys.length; i++) {
          const dataToPost = tonDayKys[i];
          delete dataToPost._id;
          await asyncPostData({
            apiCode: "cdvt",
            token,
            idApp: app?._id,
            data: {
              ...dataToPost,
              ma_vt: resp?.data?.ma_vt || "",
              ten_vt: resp?.data?.ten_vt || "",
            },
          });
        }
        setOpenBackdrop?.(false);
      }
      // dvt
      if (resp?.data?.ma_vt && dvts?.length > 0 && !isEdit) {
        setOpenBackdrop?.(true);
        for (let i = 0; i < dvts.length; i++) {
          const dataDvt = dvts[i];
          delete dataDvt._id;
          await asyncPostData({
            apiCode: "dmqddvt",
            token,
            idApp: app?._id,
            data: {
              ...dataDvt,
              ma_dvt_goc: resp?.data?.ma_dvt || "",
              ma_vt: resp?.data?.ma_vt || "",
              ten_vt: resp?.data?.ten_vt || "",
            },
          });
        }
        setOpenBackdrop?.(false);
      }
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="1000px"
      formTitle="Sản phẩm"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection
          isEdit={isEdit}
          control={control}
          register={register}
          errors={errors}
        />
        <ImageSection
          defaultValues={defaultValues}
          setThumbnails={setThumbnails}
        />
        <DvtSection
          isEdit={isEdit}
          product={defaultValues}
          dvts={dvts}
          setDvts={setDvts}
        />
        {!isEdit && <TonDauKySection data={tonDayKys} setData={setTonDauKys} />}
        <DinhMucSection register={register} />
        <DescriptionSection control={control} />
      </Stack>
    </ModalForm>
  );
}

export default FormHangHoa;
