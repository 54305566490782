import React, { Ref } from "react";
import {
  Avatar,
  Box,
  Grid2,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { isArray } from "lodash";
import { generateLinkImage, numeralCustom } from "../../../../../utils/helpers";
import { usePosContext } from "../../../PosPage";

interface IProducts {
  headerHeight: string;
  data?: any[];
  loading?: boolean;
  loadingRef?: Ref<any>;
  hasNextPage?: boolean;
}

function Products({
  headerHeight,
  data,
  loading,
  loadingRef,
  hasNextPage,
}: IProducts) {
  const { handleAddProductToDetails } = usePosContext();

  const renderLoading = (ref?: Ref<any>) => {
    return (
      <>
        {new Array(3).fill(0).map((_, index) => {
          return (
            <Grid2
              ref={index === 0 ? ref : undefined}
              key={index}
              size={{ xs: 2, md: 4, lg: 3 }}
            >
              <Stack>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%", height: "100px" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "90%", height: "16px" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "50%", height: "16px" }}
                />
              </Stack>
            </Grid2>
          );
        })}
      </>
    );
  };

  return (
    <Stack
      sx={{
        pb: 1,
        px: 2,
        width: "100%",
        overflow: "auto",
        height: `calc(100vh - ${headerHeight} - 16px)`,
      }}
    >
      {isArray(data) && data.length > 0 ? (
        <>
          <Grid2 container alignContent="flex-start" spacing={1}>
            {data.map((product) => {
              return (
                <Grid2 key={product._id} size={{ xs: 2, md: 4, lg: 3 }}>
                  <Stack
                    onClick={() =>
                      handleAddProductToDetails({
                        product,
                        gia_ban_le: product.gia_ban_le,
                        ma_dvt: product.ma_dvt,
                        ten_dvt: product.ten_dvt,
                        overwrite: true,
                      })
                    }
                    component={MenuItem}
                    sx={{
                      p: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "background.paper",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        paddingTop: "60%",
                        position: "relative",
                      }}
                    >
                      <Avatar
                        imgProps={{ loading: "lazy" }}
                        src={
                          product.picture_thumb
                            ? generateLinkImage(product.picture_thumb)
                            : "/product.png"
                        }
                        sx={{
                          position: "absolute",
                          zIndex: 1,
                          top: 0,
                          left: 0,
                          borderRadius: "0px",
                          width: "100%",
                          height: "100%",
                          "& img": {
                            objectFit: "contain",
                          },
                        }}
                      />
                    </Box>
                    <Typography
                      className="two-lines-ellipsis"
                      sx={{ px: 1, textAlign: "center", whiteSpace: "wrap" }}
                    >
                      {product.ten_vt}
                    </Typography>
                    <Typography
                      sx={{
                        px: 1,
                        textAlign: "center",
                        fontWeight: 600,
                        color: "error.main",
                        marginTop: "auto",
                      }}
                    >
                      {numeralCustom(product.gia_ban_le).format()}
                    </Typography>
                  </Stack>
                </Grid2>
              );
            })}
            {hasNextPage && renderLoading(loadingRef)}
          </Grid2>
        </>
      ) : (
        <>
          {loading ? (
            <Grid2 container spacing={2}>
              {new Array(4).fill(0).map((_, index) => {
                return (
                  <Grid2 key={index} size={{ xs: 2, md: 4, lg: 3 }}>
                    <Stack>
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        sx={{ width: "100%", height: "100px" }}
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ width: "90%", height: "16px" }}
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ width: "50%", height: "16px" }}
                      />
                    </Stack>
                  </Grid2>
                );
              })}
            </Grid2>
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              Không tìm thấy kết quả
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
}

export default Products;
