import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, ID_APP } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { isArray } from "lodash";
import { saveAs } from "file-saver";
import { TableColumn } from "react-data-table-component";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import ModalBase from "./ModalBase";
import TableApp from "../tableapp/TableApp";
import { useBackdropContext } from "../../../contexts/backdrop/BackdropProvider";
import { TbFileExport } from "react-icons/tb";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";

interface IModalExportExcel {
  open: boolean;
  apiCode: string;
  filenameExport: string;
  condition?: { [key: string]: any };
  handleClose: () => void;
}

function ModalExportExcel({
  open,
  apiCode,
  filenameExport,
  condition,
  handleClose,
}: IModalExportExcel) {
  const { showAlert } = useAlertContext();
  const { setOpenBackdrop } = useBackdropContext();
  const token = useSelector((state: any) => state.auth.token);
  const [fileExcels, setFileExcels] = useState<any[]>([]);

  const getFileExcels = async () => {
    try {
      let condition = JSON.stringify({
        code: apiCode,
      });
      const resp = await axios.get(
        `${API_URL}/exportexceltemplate?page=1&q=${condition}&access_token=${token}`
      );
      if (resp.status === 200) {
        const data = resp.data;
        if (isArray(data) && data.length > 0) {
          setFileExcels(data);
        }
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message: error?.message || error?.error || "Lỗi khi tải file excel",
      });
    }
  };

  const handleExportExcel = async (template: any) => {
    try {
      setOpenBackdrop?.(true);
      const resp = await axios.get(
        `${API_URL}/${ID_APP}/${apiCode}?type_data=xlsx&limit=1000000000&id_template=${
          template._id
        }&q=${JSON.stringify(condition || {})}&access_token=${token}`
      );
      if (resp.status === 200) {
        saveAs(resp.data, filenameExport);
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message: error?.message || error?.error || "Lỗi khi xuất file excel",
      });
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Tên file",
      selector: (row) => row.title,
      width: "250px",
      wrap: true,
    },
    {
      name: "Xuất file",
      cell: (row) => (
        <IconButton
          onClick={() => handleExportExcel(row)}
          sx={{
            backgroundColor: "primary.main",
            color: "common.white",
            borderRadius: "10px",
            "&:hover": { backgroundColor: "primary.main" },
          }}
        >
          <TbFileExport size={16} />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (apiCode) {
      getFileExcels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode]);

  return (
    <ModalBase
      width="600px"
      title="File excel"
      open={open}
      handleClose={handleClose}
      sx={{ zIndex: "9999999" }}
      actions={[
        <Button
          key={1}
          variant="contained"
          color="error"
          size="small"
          onClick={handleClose}
          sx={{
            textTransform: "none",
            color: "common.white",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <TableApp columns={columns} data={fileExcels} />
      <Stack sx={{ mt: 1 }}>
        <Typography sx={{ textAlign: "center", color: "secondary.main" }}>
          Vui lòng không thoát ra khi đang xuất file
        </Typography>
      </Stack>
    </ModalBase>
  );
}

export default ModalExportExcel;
