import {
  LuAperture,
  LuArchive,
  LuAreaChart,
  LuArrowRightLeft,
  LuBadgePercent,
  LuBanknote,
  LuBarChartBig,
  LuBarChartHorizontalBig,
  LuBookOpenCheck,
  LuBox,
  LuCircleDollarSign,
  LuContainer,
  LuFile,
  LuFileEdit,
  LuFileInput,
  LuFileMinus2,
  LuFileOutput,
  LuFilePlus2,
  LuFileX,
  LuGift,
  LuLineChart,
  LuPieChart,
  LuPopcorn,
  LuShapes,
  LuStore,
  LuTable,
  LuTable2,
  LuTablets,
  LuTicket,
  LuUser2,
  LuUserCheck,
  LuUsers,
  LuUsers2,
  LuUserSquare2,
} from "react-icons/lu";
import { MenuItemType, MenuType } from "../types/menu.type";
import { ContainerOwnProps } from "@mui/material";
import { INavItem } from "../types/nav.type";

const HEADER_HEIGHT: string = "50px";
const NAVBAR_HEIGHT: string = "42px";
const SIDEBAR_MINWIDTH: string = "72px";
const SIDEBAR_MAXWIDTH: string = "260px";
const MOBILE_CONTAINER: ContainerOwnProps["maxWidth"] = "sm";

const LOAI_CHIET_KHAU = [
  {
    value: "1",
    label: "Chiết khấu hóa đơn",
  },
  {
    value: "2",
    label: "Chiết khẩu sản phẩm",
  },
];

const MENUS: MenuType[] = [
  {
    subheader: "Tổng quan",
    items: [
      {
        text: "Thống kê bán hàng",
        path: "/dashboard",
        Icon: LuLineChart,
      },
    ],
  },
  {
    subheader: "Quản lý siêu thị",
    items: [
      { text: "Danh sách Sản phẩm", Icon: LuShapes, path: "/list/hanghoa" },
      { text: "Nhóm Sản phẩm", Icon: LuContainer, path: "/list/nhomhang" },
      { text: "Quy đổi đơn vị tính", Icon: LuAperture, path: "/list/dmqddvt" },
      { text: "Tồn đầu kỳ", Icon: LuArchive, path: "/list/cdvt" },
      { text: "Kiểm kê", Icon: LuBookOpenCheck, path: "/list/pkk" },
      { text: "Phiếu bán lẻ", Icon: LuFile, path: "/list/pbl" },
      { text: "Trả hàng", Icon: LuFileEdit, path: "/list/hd7" },
      { text: "Nhập hàng", Icon: LuFileInput, path: "/list/pn1" },
      { text: "Trả hàng nhập", Icon: LuFileOutput, path: "/list/pn5" },
      { text: "Xuất hủy", Icon: LuFileX, path: "/list/pxk" },
    ],
  },
  {
    subheader: "Quản lý quán ăn",
    items: [
      { text: "Danh sách món", Icon: LuPopcorn, path: "/list/monan" },
      { text: "Nhóm món", Icon: LuContainer, path: "/list/nhommon" },
      { text: "Đơn hàng", Icon: LuFile, path: "/list/hd_nh" },
      { text: "Danh sách bàn", Icon: LuTable, path: "/list/dmban" },
      { text: "Nhóm bàn", Icon: LuTable2, path: "/list/nhomban" },
    ],
  },
  {
    subheader: "Thu chi",
    items: [
      { text: "Phiếu thu", Icon: LuFilePlus2, path: "/list/pt1" },
      { text: "Phiếu chi", Icon: LuFileMinus2, path: "/list/pc1" },
      // { text: "Sổ quỹ", Icon: LuCoins, path: "/report/soquy" },
    ],
  },
  {
    subheader: "Đối tác",
    items: [
      { text: "Khách hàng", Icon: LuUser2, path: "/list/customer" },
      { text: "Nhóm khách", Icon: LuUsers, path: "/list/dmnhkh" },
      { text: "Nhà cung cấp", Icon: LuUserCheck, path: "/list/dmncc" },
      { text: "Nhóm nhà cung cấp", Icon: LuUsers2, path: "/list/dmnhncc" },
    ],
  },
  {
    subheader: "Hệ thống",
    items: [
      {
        text: "Nhóm người dùng và phân quyền",
        Icon: LuUsers,
        path: "/list/usergroup",
      },
      {
        text: "Người dùng",
        Icon: LuUser2,
        path: "/list/participant",
      },
      { text: "Đơn vị tính", Icon: LuTablets, path: "/list/dmdvt" },
      {
        text: "Chi nhánh",
        Icon: LuStore,
        path: "/list/dmkho",
      },
    ],
  },
];

const HEADER_OPTIONS: MenuItemType[] = [];

const NAVBAR_ITEMS: INavItem[] = [
  {
    text: "Tổng quan",
    link: "/",
    endIcon: <LuBarChartBig size={16} />,
  },
  {
    text: "Sản phẩm",
    endIcon: <LuBox size={16} />,
    subs: [
      {
        text: "Danh sách Sản phẩm",
        Icon: <LuShapes size={16} />,
        link: "/list/hanghoa",
      },
      {
        text: "Đơn vị tính",
        Icon: <LuTablets size={16} />,
        link: "/list/dmdvt",
      },
      {
        text: "Nhóm Sản phẩm",
        Icon: <LuContainer size={16} />,
        link: "/list/nhomhang",
      },
      // {
      //   text: "Nhóm trên POS",
      //   Icon: <LuContainer size={16} />,
      //   link: "/list/nhomhangpos",
      // },
      {
        text: "Quy đổi đơn vị tính",
        Icon: <LuAperture size={16} />,
        link: "/list/dmqddvt",
      },
      { text: "Tồn đầu kỳ", Icon: <LuArchive size={16} />, link: "/list/cdvt" },
      {
        text: "Kiểm kê",
        Icon: <LuBookOpenCheck size={16} />,
        link: "/list/pkk",
      },
    ],
  },
  {
    text: "Giao dịch",
    endIcon: <LuArrowRightLeft size={16} />,
    subs: [
      { text: "Phiếu bán lẻ", Icon: <LuFile size={16} />, link: "/list/pbl" },
      {
        text: "Phiếu bán hàng app",
        Icon: <LuFile size={16} />,
        link: "/list/sogcapp",
      },
      {
        text: "Phiếu bán hàng Web",
        Icon: <LuFile size={16} />,
        link: "/list/pbl_web",
      },
      { text: "Trả hàng", Icon: <LuFileEdit size={16} />, link: "/list/hd7" },
      // { text: "Mua hàng trong nước", Icon: <LuFileInput size={16} />, link: "/list/pn1" },
      { text: "Nhập hàng", Icon: <LuFileInput size={16} />, link: "/list/pnk" },
      {
        text: "Xuất điều chuyển",
        Icon: <LuFileOutput size={16} />,
        link: "/list/pxc",
      },
      {
        text: "Nhập điều chuyển",
        Icon: <LuFileInput size={16} />,
        link: "/list/pnc",
      },
      // {
      //   text: "Trả hàng nhập",
      //   Icon: <LuFileOutput size={16} />,
      //   link: "/list/pn5",
      // },
      // { text: "Xuất hủy", Icon: <LuFileX size={16} />, link: "/list/pxk" },
    ],
  },
  {
    text: "Sổ quỹ",
    endIcon: <LuCircleDollarSign size={16} />,
    subs: [
      { text: "Phiếu thu", Icon: <LuFilePlus2 size={16} />, link: "/list/pt1" },
      {
        text: "Phiếu chi",
        Icon: <LuFileMinus2 size={16} />,
        link: "/list/pc1",
      },
      {
        text: "Báo cáo sổ quỹ",
        Icon: <LuBanknote size={16} />,
        link: "/report/soquy",
      },
    ],
  },
  {
    text: "Đối tác",
    endIcon: <LuUserSquare2 size={16} />,
    subs: [
      {
        text: "Khách hàng",
        Icon: <LuUser2 size={16} />,
        link: "/list/customer",
      },
      { text: "Nhóm khách", Icon: <LuUsers size={16} />, link: "/list/dmnhkh" },
      {
        text: "Nhà cung cấp",
        Icon: <LuUserCheck size={16} />,
        link: "/list/dmncc",
      },
      {
        text: "Nhóm nhà cung cấp",
        Icon: <LuUsers2 size={16} />,
        link: "/list/dmnhncc",
      },
    ],
  },
  {
    text: "Giá bán",
    endIcon: <LuTicket size={16} />,
    subs: [
      {
        text: "Chiết khấu sản phẩm",
        Icon: <LuBadgePercent size={16} />,
        link: "/list/chietkhausp",
      },
      {
        text: "Khuyến mãi, quà tặng",
        Icon: <LuGift size={16} />,
        link: "/list/dmkhuyenmai",
      },
    ],
  },
  {
    text: "Báo cáo",
    endIcon: <LuPieChart size={16} />,
    subs: [
      {
        text: "Báo cáo bán hàng",
        Icon: <LuBarChartBig size={16} />,
        link: "/report/banhang",
      },
      {
        text: "Báo cáo Sản phẩm",
        Icon: <LuAreaChart size={16} />,
        link: "/report/hanghoa",
      },
      {
        text: "Báo cáo kết quả kinh doanh",
        Icon: <LuBarChartHorizontalBig size={16} />,
        link: "/report/kqhdkd",
      },
    ],
  },
];

const STORE_KEY = "store_key";
const BILL_KEY = "bill_key";

export {
  HEADER_HEIGHT,
  SIDEBAR_MINWIDTH,
  SIDEBAR_MAXWIDTH,
  MENUS,
  HEADER_OPTIONS,
  STORE_KEY,
  BILL_KEY,
  MOBILE_CONTAINER,
  NAVBAR_HEIGHT,
  NAVBAR_ITEMS,
  LOAI_CHIET_KHAU,
};
