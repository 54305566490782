import React from "react";
import { Avatar, Box, Grid2, Stack } from "@mui/material";
import { generateLinkImage, numeralCustom } from "../../../../../utils/helpers";
import TextInput from "../../../input/TextInput";
import CheckboxInput from "../../../input/CheckboxInput";

interface IInfoSection {
  data: any;
}

function InfoSection({ data }: IInfoSection) {
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Grid2 container spacing={4}>
        <Grid2 size={{ xs: 12, md: 2 }}>
          <Avatar
            src={
              data?.picture_thumb
                ? generateLinkImage(data?.picture_thumb)
                : "/product.png"
            }
            alt="product thumbnail"
            sx={{
              borderRadius: "10px",
              width: "100%",
              height: "auto",
              border: "1px dashed",
              borderColor: "primary.main",
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <Stack gap={2}>
            <TextInput
              readOnly
              labelWidth="25%"
              label="Mã SP"
              value={data?.ma_vt}
            />
            <TextInput
              readOnly
              labelWidth="25%"
              label="Tên sản phẩm"
              value={data?.ten_vt}
            />
            <TextInput
              readOnly
              labelWidth="25%"
              label="Nhóm hàng"
              value={data?.ten_nvt}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <Stack gap={2}>
            <TextInput
              readOnly
              labelWidth="25%"
              label="Đơn vị tính"
              value={data?.ten_dvt}
            />
            <TextInput
              readOnly
              labelWidth="25%"
              label="Giá bán"
              endAdornment="VND"
              value={numeralCustom(data?.gia_ban_le).format()}
            />
            <TextInput
              readOnly
              labelWidth="25%"
              label="Vị trí"
              value={data?.vi_tri}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Stack direction="row" gap={2} alignItems="center">
            <CheckboxInput
              label="Bán trên POS"
              checked={data?.sold_on_pos}
              readOnly
            />
            <CheckboxInput
              label="Bán trên APP"
              checked={data?.is_app}
              readOnly
            />
            <CheckboxInput
              label="Bán trên WEB"
              checked={data?.sold_on_web}
              readOnly
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default InfoSection;
