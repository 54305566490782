import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import TableApp from "../../components/common/tableapp/TableApp";
import { HEADER_HEIGHT, NAVBAR_HEIGHT } from "../../utils/ui.constants";
import Tablepagination from "../../components/common/tableapp/TablePagination";
import { ListType } from "../../types/list.type";
import { asyncSearchList } from "../../utils/httpRequests";
import { useSelector } from "react-redux";
import { LuPlusCircle } from "react-icons/lu";
import Options from "./components/Options";
import { useAlertContext } from "../../contexts/alert/AlertProvider";
import Actions from "./components/Actions";

interface IListBase {
  category: ListType;
}

const heights = {
  header: HEADER_HEIGHT,
  py: "16px",
  topList: "50px",
  pagination: "42px",
};

function ListBase({ category }: IListBase) {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const [data, setData] = useState<any[]>([]);
  const [load, setLoad] = useState<number>(0);
  const [checkFields, setCheckFields] = useState<
    { value: string; label: string }[]
  >([]);
  const [condition, setCondition] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [toggleClear, setToggleClear] = useState<boolean>(false);
  const [paginationOption, setPaginationOption] = useState<{
    page: number;
    limit: number;
    totalRows: number;
  }>({
    limit: 20,
    page: 1,
    totalRows: 0,
  });

  const handleRowClicked = (row: any) => {
    if (category.Form) {
      setClickedData(row);
      setOpenModalForm(true);
      setIsEdit(true);
    }
  };

  const handleCloseModalForm = () => {
    setClickedData(null);
    setOpenModalForm(false);
    setIsEdit(false);
  };

  // get products
  const getListData = async () => {
    try {
      if (loading) return;
      setLoading(true);
      let query: any = { ...category.condition, ...condition };
      const resp = await asyncSearchList({
        apiCode: category.apiCode,
        withIdApp: true,
        token,
        condition: {
          limit: paginationOption.limit,
          page: paginationOption.page,
          q: query,
        },
      });
      const respCount = await asyncSearchList({
        apiCode: category.apiCode,
        token,
        condition: {
          count: 1,
          q: query,
        },
      });
      if (respCount.status === 200) {
        setPaginationOption({
          ...paginationOption,
          totalRows: respCount.data.rows_number || 0,
        });
      } else {
        showAlert({
          type: "error",
          message:
            respCount?.data?.error ||
            respCount?.data?.message ||
            "Lỗi khi tải dữ liệu",
        });
      }
      if (resp.status === 200) {
        setData(resp.data || []);
        setLoading(false);
      } else {
        showAlert({
          type: "error",
          message:
            respCount?.data?.error ||
            respCount?.data?.message ||
            "Lỗi khi tải dữ liệu",
        });
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.data?.message || error?.data?.error || "Lỗi khi tải dữ liệu",
      });
    } finally {
      setToggleClear(!toggleClear);
      setSelectedRows([]);
    }
  };

  // row per page change
  const handleRowPerPageChange = (value: number) => {
    setPaginationOption({ ...paginationOption, page: 1, limit: value });
  };

  useEffect(() => {
    if (category.apiCode) {
      getListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, load]);

  useEffect(() => {
    if (category.apiCode) {
      setCondition([]);
      setPaginationOption({ limit: 20, page: 1, totalRows: 0 });
      setCheckFields(category.fields || []);
      setLoad(load + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.apiCode, category.condition]);
  useEffect(() => {
    setPaginationOption({ limit: 20, page: 1, totalRows: 0 });
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return (
    <>
      {/* Form  */}
      {category?.Form && openModalForm && (
        <category.Form
          apiCode={category?.apiCode}
          isEdit={isEdit}
          defaultValues={clickedData}
          open={openModalForm}
          onClose={handleCloseModalForm}
          setLoad={setLoad}
        />
      )}
      {/* Form end */}
      <Box
        sx={{
          backgroundColor: "background.default",
          width: "100%",
          minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
          height: "auto",
          py: `calc(${heights.py} / 2)`,
        }}
      >
        <Container>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 2.5 }}>
              <Stack
                sx={{
                  width: "100%",
                  pr: 0.5,
                  height: `calc(100vh - ${heights.py})`,
                  overflow: "auto",
                }}
              >
                {category?.Filter && (
                  <category.Filter setCondition={setCondition} />
                )}
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 9.5 }}>
              <Stack
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    flexShrink: 0,
                    height: heights.topList,
                    px: 2,
                    borderBottom: "1px dashed",
                    borderColor: "divider",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "20px",
                      fontWeight: 600,
                    }}
                  >
                    {category?.title || "Danh sách"}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Box
                      sx={{
                        visibility:
                          selectedRows?.length > 0 ? "visible" : "hidden",
                      }}
                    >
                      <Actions
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        setToggleClear={setToggleClear}
                        apiCode={category?.apiCode}
                        setLoad={setLoad}
                      />
                    </Box>
                    {category?.allowCreate && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                          boxShadow: "none",
                          color: "common.white",
                          textTransform: "none",
                          "&:hover": { boxShadow: "none" },
                        }}
                        startIcon={<LuPlusCircle size={16} />}
                        onClick={() => setOpenModalForm(true)}
                      >
                        Thêm
                      </Button>
                    )}
                    <Options
                      category={category}
                      checkFields={checkFields}
                      setCheckFields={setCheckFields}
                      setLoad={setLoad}
                      condition={condition}
                    />
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    width: "100%",
                    height: `calc(100vh - ${heights.topList} - ${heights.pagination} - ${heights.py})`,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TableApp
                      toggleCleared={toggleClear}
                      rowPerPage={paginationOption.limit}
                      columns={category.columns}
                      data={data}
                      selectableRows
                      fixedHeader
                      progressPending={loading}
                      onRowClicked={
                        !category?.Expand ? handleRowClicked : undefined
                      }
                      onSelectedRowsChange={setSelectedRows}
                      expandableRows={!!category?.Expand}
                      expandOnRowClicked={!!category?.Expand}
                      expandableRowsHideExpander
                      expandableRowsComponent={
                        category.Expand
                          ? ({ data }) => (
                              <>
                                {category?.Expand && (
                                  <category.Expand
                                    data={data}
                                    onOpenEdit={() => handleRowClicked(data)}
                                    setLoad={setLoad}
                                  />
                                )}
                              </>
                            )
                          : undefined
                      }
                      fixedHeaderScrollHeight={`calc(100vh - ${heights.py} - ${heights.pagination} - ${heights.topList})`}
                    />
                  </Box>
                </Box>
              </Stack>
              {/* Table end */}
              {/* Pagination */}
              <Box
                sx={{
                  mt: "auto",
                  borderTop: "1px dashed",
                  borderColor: "divider",
                  height: heights.pagination,
                }}
              >
                {paginationOption.totalRows > 0 && (
                  <Tablepagination
                    currentPage={paginationOption.page}
                    onChangePage={(value) =>
                      setPaginationOption({ ...paginationOption, page: value })
                    }
                    rowsPerPage={paginationOption.limit}
                    rowCount={paginationOption.totalRows}
                    onChangeRowsPerPage={handleRowPerPageChange}
                    paginationRowsPerPageOptions={[20, 50, 100, 500]}
                  />
                )}
              </Box>
              {/* Pagination end */}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default ListBase;
