import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import FilterText from "../FilterText";
import FilterContainer from "../FilterContainer";
import { useBackdropContext } from "../../../../contexts/backdrop/BackdropProvider";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { asyncSearchList } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { ID_APP } from "../../../../utils/constants";
import FilterList from "../FilterList";
import FilterRadios from "../FilterRadios";
import { IFilterProps } from "../../../../types/filter.type";

function FilterHangHoa({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    vat_tu: "",
    slug: "",
    nhom_vat_tu: [],
    trang_thai: "true",
  };
  const token = useSelector((state: any) => state.auth.token);
  const { setOpenBackdrop } = useBackdropContext();
  const { showAlert } = useAlertContext();
  const [groups, setGroups] = useState<any[]>([]);
  const [filter, setFilter] = useState<{ [key: string]: any }>(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const getNvt = async () => {
    try {
      setOpenBackdrop?.(true);
      const resp = await asyncSearchList({
        apiCode: "dmnvt",
        token,
        idApp: ID_APP,
        condition: { page: 1, limit: 9999999 },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi tải thông tin nhóm hàng",
        });
        return;
      }
      setGroups(resp?.data || []);
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  const productGroups = useMemo(() => {
    if (!groups || !Array.isArray(groups)) return [];
    return (groups || []).reduce((acc, group) => {
      return [...acc, { label: group.ten_nvt, value: group._id }];
    }, []);
  }, [groups]);

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.vat_tu) {
      condition.$or = [
        {
          ma_vt: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_vt: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_vt_en: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    if ((filter.nhom_vat_tu || []).length > 0) {
      condition.ma_nvt = { $in: filter.nhom_vat_tu };
    }
    switch (filter.trang_thai) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default: // all
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  useEffect(() => {
    getNvt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack gap={1}>
        <FilterText
          title="Mã, Tên sản phẩm"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.vat_tu}
          onSearch={(value) => setFilter({ vat_tu: value })}
        />
        <FilterList
          title="Nhóm hàng"
          items={productGroups}
          defaultValues={filter.nhom_vat_tu}
          onChange={(value) => setFilter({ ...filter, nhom_vat_tu: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: "Tất cả", value: "all" },
            { label: "Đang kinh doanh", value: "true" },
            { label: "Ngừng kinh doanh", value: "false" },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterHangHoa;
