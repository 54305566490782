import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Rating, Stack } from "@mui/material";
import SelectApiInput from "../../input/SelectApiInput";
import TextInput from "../../input/TextInput";
import WrapperInput from "../../input/WrapperInput";

const schema = yup.object({
  ten_kh: yup.string().required("Vui lòng nhập tên"),
  content: yup.string().required("Vui lòng nhập nội dung đánh giá"),
});

interface IFormComment2 {
  publicMode?: boolean;
}

function FormComment2({
  defaultValues,
  isEdit,
  apiCode,
  open,
  publicMode = false,
  onClose,
  setLoad,
}: IFormComment2 & IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    vat_tu: null,
    rate: 5,
    ten_kh: "",
    dien_thoai_kh: "",
    content: "",
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ten_kh: defaultValues?.exfields?.ten_kh || "",
        vat_tu: defaultValues?.of_id
          ? {
              _id: defaultValues?.of_id,
              ten_vt: defaultValues?.of_name,
            }
          : null,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { vat_tu, ten_kh, rate, ...fields } = values;
    return {
      ...fields,
      rate: rate || 1,
      of_id: vat_tu?._id,
      of_name: vat_tu?.name,
      exfields: {
        ...(defaultValues?.exfields || {}),
        ten_kh,
      },
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token: publicMode ? "" : token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token: publicMode ? "" : token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="500px"
      formTitle="Đánh giá"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <Controller
          control={control}
          name="vat_tu"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                labelWidth="25%"
                label="Sản phẩm"
                placeholder="Chọn sản phẩm"
                value={value}
                onChange={onChange}
                apiCode="dmvt"
                searchFields={["ma_vt", "ten_vt"]}
                getOptionLabel={(option) => option.ten_vt}
                checkEqual={(value, option) => value._id === option._id}
              />
            );
          }}
        />
        <TextInput
          required
          label="Tên"
          labelWidth="25%"
          placeholder="Nhập tên người đánh giá"
          name="ten_kh"
          register={register}
          errorMessage={errors?.ten_kh?.message as string}
        />
        <TextInput
          required
          multiline
          label="Nội dung"
          labelWidth="25%"
          placeholder="Nhập nội dung đánh giá"
          name="content"
          register={register}
          errorMessage={errors?.content?.message as string}
        />
        <TextInput
          label="SĐT"
          labelWidth="25%"
          placeholder="Nhập số điện thoại"
          name="dien_thoai_kh"
          register={register}
        />
        <Controller
          name="rate"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <WrapperInput required label="Đánh giá" labelWidth="25%">
                <Rating
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                />
              </WrapperInput>
            );
          }}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormComment2;
