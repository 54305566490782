import React from "react";
import { IExpand } from "../../../../types/expand.type";
import { Button, Stack } from "@mui/material";
import Tabbase from "../../tab/Tabbase";
import InfoSection from "./info-section/InfoSection";
import { asyncDelete } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import KhoSection from "./kho-section/KhoSection";
import WrapperExpand from "../WrapperExpand";
import DescriptionSection from "./description-section/DescriptionSection";
import VoteSection from "./vote-section/VoteSection";

function ExpandHangHoa({ data, onOpenEdit, setLoad }: IExpand) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();

  const handleDelete = async () => {
    const resp = await asyncDelete({
      apiCode: "dmvt",
      id: data?._id,
      token,
      idApp: app?._id,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi xóa dữ liệu",
      });
    } else {
      showAlert({ type: "success", message: `Đã xóa thành công` });
      setLoad?.((prev) => prev + 1);
    }
  };

  return (
    <WrapperExpand
      data={data}
      action={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ pb: 2 }}
        >
          <Button
            onClick={onOpenEdit}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Chỉnh sửa
          </Button>
          <Button
            onClick={() => {
              showAlert({
                title: "Xác nhận xóa",
                type: "info",
                message: "Bạn có chắc muốn xóa dòng này không ?",
                onConfirm: handleDelete,
              });
            }}
            variant="contained"
            size="small"
            color="error"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Xóa
          </Button>
        </Stack>
      }
    >
      <Tabbase
        defaultActive="thongtinchung"
        tabs={[
          { label: "Thông tin chung", value: "thongtinchung" },
          { label: "Mô tả", value: "mota" },
          { label: "Tồn kho", value: "tonkho" },
          { label: "Đánh giá", value: "vote" },
        ]}
        panels={[
          { value: "thongtinchung", content: <InfoSection data={data} /> },
          { value: "mota", content: <DescriptionSection data={data} /> },
          { value: "tonkho", content: <KhoSection product={data} /> },
          { value: "vote", content: <VoteSection product={data} /> },
        ]}
      />
    </WrapperExpand>
  );
}

export default ExpandHangHoa;
