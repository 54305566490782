import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { LuMoreVertical } from "react-icons/lu";
import ModalImportExcel from "../../../components/common/modal/ModalImportExcel";
import ModalExportExcel from "../../../components/common/modal/ModalExportExcel";
import { ListType } from "../../../types/list.type";
import ModalFieldDuplicate from "../../../components/common/modal/ModalFieldDuplicate";

interface IOptions {
  category: ListType;
  checkFields: { value: string; label: string }[];
  condition?: { [key: string]: any };
  setLoad: Dispatch<SetStateAction<number>>;
  setCheckFields: Dispatch<SetStateAction<{ value: string; label: string }[]>>;
}

function Options({
  category,
  checkFields,
  condition,
  setLoad,
  setCheckFields,
}: IOptions) {
  const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [openExportExcel, setOpenExportExcel] = useState(false);
  const [openModalDuplicate, setOpenModalDuplicate] = useState(false);

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <>
      {openImportExcel && (
        <ModalImportExcel
          open={openImportExcel}
          handleClose={() => setOpenImportExcel(false)}
          apiCode={category?.apiCode}
          setLoad={setLoad}
          columns={category?.columns}
        />
      )}
      {openExportExcel && (
        <ModalExportExcel
          open={openExportExcel}
          handleClose={() => setOpenExportExcel(false)}
          apiCode={category?.apiCode}
          condition={condition}
          filenameExport={`${category?.apiCode}_export.xlsx`}
        />
      )}
      <ModalFieldDuplicate
        open={openModalDuplicate}
        category={category}
        checkFields={checkFields}
        setCheckFields={setCheckFields}
        onClose={() => setOpenModalDuplicate(false)}
      />
      <Menu open={!!anchorMenu} anchorEl={anchorMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={() => setOpenImportExcel(true)}>Nhập excel</MenuItem>
        <MenuItem onClick={() => setOpenExportExcel(true)}>Xuất excel</MenuItem>
        <MenuItem onClick={() => setOpenModalDuplicate(true)}>
          Kiểm tra trùng dữ liệu
        </MenuItem>
      </Menu>
      {/* Button */}
      <Button
        onClick={(e) => setAnchorMenu(e.currentTarget)}
        variant="contained"
        color="primary"
        size="small"
        sx={{
          boxShadow: "none",
          color: "common.white",
          textTransform: "none",
          "&:hover": { boxShadow: "none" },
        }}
        startIcon={<LuMoreVertical size={16} />}
      >
        Tùy chọn
      </Button>
    </>
  );
}

export default Options;
